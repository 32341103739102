
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

import { faSms, faPhoneVolume, faShare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Redirect } from 'react-router-dom';
import { cambiopass } from "../../actions/auth";



import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import Swal from 'sweetalert2';

import Registro_UserRec from "../../components/Registro_UserRec";
import SmsService from "../../services/sms.service";

import axios from "axios";



const API_URL = process.env.REACT_APP_API_SMS_URL;




const ThirdStep = (props) => {

  const { user } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    defaultValues: {
      user_respuesta_sms: user.user_respuesta_sms,
      user_password: user.user_password,
      user_password1: user.user_password1,
    }
  });


  const user_password = useRef({});
  user_password.current = watch("user_password", "");

  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(state => state.auth);

  const onSubmit = (data) => {
    setIsLoading(true);
    try {

      user.user_password = user_password.current;

      if (!user.user_password) {
        
        setIsLoading(false);
        return false
      }

      var cadena1 = user.user_fono.slice(1, user.user_fono.length);
      var cadena2 = data.user_codverificacion;
      axios.get(API_URL + "verify?", {
        params: {
          phonenumber: cadena1,
          code: cadena2
        }
      })
        .then(response => {
          user.user_respuesta_sms = response.data;

          props.updateUser(data);
          setIsLoading(true);

          //console.log(user);

          dispatch(
            cambiopass(
              user.user_id,
              user.user_nombre,
              user.user_fono,
              user.user_email,
              user.user_usuario,
              user.user_password,
              user.user_respuesta_sms,
              user.user_pais,
              user.user_currentLatitude,
              user.user_currentLongitude,
              user.user_accuracy)
          )
            .then((datos) => {
              //console.log(datos)
              Swal.fire({
                title: '<strong>Se cambio la Password con exito.!</strong>',
                icon: 'success',
                html:
                  user.user_nombre.toUpperCase() + " para iniciar sesión en el Sistema -> " + '<a href="/">CLICK AQUÍ.!</a> ',
                showConfirmButton: false,
                imageUrl: process.env.REACT_APP_LOGOEMPRESA,
                imageWidth: 220,
                imageHeight: 45,
                timer: 20000
              })
            })
            .catch(() => {
              setIsLoading(false);
              Swal.fire({
                icon: 'error',
                title: "Error al verificar: (" + user.user_nombre + ")",
                showConfirmButton: false,
                text: "El código ingresado (" + data.user_codverificacion + ") es incorrecto o ya esta no esta activo el usuario.!",
                timer: 10000
              });
            });
        })
        .catch(error => {

          setIsLoading(false);
          Swal.fire({
            icon: 'error',
            title: "Error al verificar: (" + user.user_nombre + ")",
            showConfirmButton: false,
            text: "El código ingresado (" + data.user_codverificacion + ") es incorrecto o ya esta no esta activo el usuario.!",
            timer: 10000
          });

          return (error)
        })
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Se detecto un error, vuelva a intentar más tarde',
        text: error.response.data,
        timer: 10000
      });
      console.log('error', error.response.data);
    }
  };

  const onSubmitEnviarSms = (data) => {
    try {
      //SmsService.getEnviar(user.user_fono);
      if (user.user_fono.length > 9) {
        SmsService.GetEnviar(user.user_fono, 'sms');
        setIsLoading1(true);
        Swal.fire({
          icon: 'warning',
          title: "Verificación: (" + user.user_nombre.toUpperCase() + ")",
          showConfirmButton: false,
          html: "<b>acabamos de enviarte un SMS con un código </b><br/><br/>Por favor ingrese el codigo enviado en el formulario. Tenga en cuenta que la entrega de SMS puede tardar un minuto o más.",
          timer: 10000
        })
      } else {
        //update action
        setIsLoading1(false);
        Swal.fire({
          icon: 'error',
          title: "(" + user.user_nombre.toUpperCase() + "), Se detecto un error con Teléfono",
          showConfirmButton: false,
          html: "<b>No existe un teléfono ingresado ó no tiene el formato correcto.! </b><br/><br/>Por favor ingreselo nuevamente en el Paso 2.", timer: 10000
        })
      }
    } catch (error) {
      if (error.response) {
        setIsLoading1(false);
        Swal.fire({
          icon: 'error',
          title: "(" + user.user_nombre.toUpperCase() + "), Se detecto un error con Teléfono",
          showConfirmButton: false,
          html: "<b>No existe un teléfono ingresado ó no tiene el formato correcto.! </b><br/><br/>Por favor ingreselo nuevamente en el Paso 2.", timer: 10000
        });
      }
    }
  };

  const onSubmitEnviarCall = (data) => {
    try {
      //SmsService.getEnviar(user.user_fono);
      if (user.user_fono.length > 9) {
        SmsService.GetEnviar(user.user_fono, 'call');
        setIsLoading1(true);
        Swal.fire({
          icon: 'warning',
          title: "Verificación: (" + user.user_nombre.toUpperCase() + ")",
          showConfirmButton: false,
          html: "<b>te vamos a llamar para entregar tú código </b><br/><br/>Por favor ingrese el codigo en el formulario. Tenga en cuenta que la llamada puede tardar un minuto o más.",
          timer: 10000
        })

      } else {
        //update action
        setIsLoading1(false);
        Swal.fire({
          icon: 'error',
          title: "(" + user.user_nombre.toUpperCase() + "), Se detecto un error con Teléfono",
          showConfirmButton: false,
          html: "<b>No existe un teléfono ingresado ó no tiene el formato correcto.! </b><br/><br/>Por favor ingreselo nuevamente en el Paso 2.", timer: 10000
        })
      }
    } catch (error) {
      if (error.response) {
        setIsLoading1(false);
        Swal.fire({
          icon: 'error',
          title: "(" + user.user_nombre.toUpperCase() + "), Se detecto un error con Teléfono",
          showConfirmButton: false,
          html: "<b>No existe un teléfono ingresado ó no tiene el formato correcto.! </b><br/><br/>Por favor ingreselo nuevamente en el Paso 2.", timer: 10000
        });
      }
    }
  };


  if (isLoggedIn) {
    return <Redirect from="/" to="/admin/dashboard" />;
  }

  return (
    <Form className="col-titulo-login" onSubmit={handleSubmit(onSubmit)}>
      <Registro_UserRec />
      <motion.div
        initial={{ x: '-100vw' }}
        animate={{ x: 0 }}
        transition={{ stiffness: 150 }}
      >


        <Form.Group className="um_data" controlid="reg_user">

          <Form.Label><h6>Revise los datos ingresados:</h6></Form.Label>


          <Row>
            <Col sm={3}>
              <Form.Label>Email</Form.Label>
            </Col>
            <Col sm={9} className="rev-text">
              <Form.Label>{user.user_email.toUpperCase()}</Form.Label>
            </Col>
          </Row>

          <Row>
            <Col sm={3}>
              <Form.Label>Usuario</Form.Label>
            </Col>
            <Col sm={9} className="rev-text">
              <Form.Label>{user.user_usuario.toUpperCase()}</Form.Label>
            </Col>
          </Row>

        </Form.Group>
        <Form.Group className="um_data" controlid="terminosycondiciones">
          <Form.Label><h6>{user.user_nombre.toUpperCase()}, Ingrese su nueva password:</h6></Form.Label>
          <Row className="inline-block mb-3">
            <Col sm={6}>
              <Form.Group controlid="user_password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="user_password"
                  placeholder="(4-12 digitos)"
                  autoComplete="new-password"

                  {...register("user_password", {
                    required: {
                      value: true,
                      message: 'Password es requerido'
                    },
                    maxLength: {
                      value: 12,
                      message: 'No más de 12 digitos!'
                    },
                    minLength: {
                      value: 4,
                      message: 'Mínimo 4 digitos!'
                    },
                    pattern: {
                      value: /^[0-9]{4,12}$/,
                      message: 'Password sólo puede tener de 4 a 12 digitos.'
                    }
                  })}
                  className={`${errors.user_password ? 'input-error' : ''}`}
                />
                {errors.user_password && (
                  <p className="errorMsg">{errors.user_password.message}</p>
                )}
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group controlid="user_password1">
                <Form.Label>Repetir Password</Form.Label>
                <Form.Control
                  type="password"
                  name="user_password1"
                  placeholder="Confirmar password"
                  autoComplete="new-password"

                  {...register("user_password1", {
                    validate: value =>
                      value === user_password.current || "Las password no son iguales.!",
                    required: {
                      value: true,
                      message: 'Repetir el Password es requerido'
                    },
                    maxLength: {
                      value: 12,
                      message: 'No más de 12 digitos!'
                    },
                    minLength: {
                      value: 4,
                      message: 'Mínimo 4 digitos!'
                    },
                    pattern: {
                      value: /^[0-9]{4,12}$/,
                      message: 'Password sólo puede tener de 4 a 12 digitos.'
                    }
                  })}
                  className={`${errors.user_password1 ? 'input-error' : ''}`}
                />
                {errors.user_password1 && (
                  <p className="errorMsg">{errors.user_password1.message}</p>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className="um_data" controlid="cod_verificacion">
          <Row className="inline-block mb-3">
            <Col sm={4}>
              <Button className="btn1" variant="warning" onClick={onSubmitEnviarSms} size="sm" block disabled={isLoading1}>
                <span>
                  {isLoading1 ? 'Código enviado' : 'Código vía '}
                </span>
                <FontAwesomeIcon icon={faSms} className="inside-button" />
              </Button>
            </Col>
            <Col sm={4}>
              <Button className="btn1" variant="warning" onClick={onSubmitEnviarCall} size="sm" block disabled={isLoading1}>
                <span>
                  {isLoading1 ? 'Código enviado' : 'Código vía '}
                </span>
                <FontAwesomeIcon icon={faPhoneVolume} className="inside-button" />
              </Button>
            </Col>
            <Col sm={4}>
              <Form.Control
                type="text"
                name="user_codverificacion"
                placeholder="ingrese código"
                autoComplete="off"
                {...register("user_codverificacion", {
                  required: {
                    value: true,
                    message: 'Código es requerido'
                  },
                  pattern: {
                    value: /^[0-9]{6}$/,
                    message: 'Código solo puede tener 6 digitos.'
                  }
                })}
                className={`${errors.user_codverificacion ? 'input-error' : ''}`}
              />
              {errors.user_codverificacion && (
                <p className="errorMsg">{errors.user_codverificacion.message}</p>
              )}
            </Col>
          </Row>
        </Form.Group>


        <Button variant="success" type="submit" size="lg" block value="recuperar" disabled={isLoading}>
          <span>
            {isLoading ? <span>Verificando... <Spinner animation="grow" variant="warning" /> </span> : 'Registrar nueva Password'}
          </span>
        </Button>
      </motion.div>
    </Form>
  );
};

export default ThirdStep;
