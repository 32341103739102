import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  TURNO_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
} from './types'

import AuthService from '../services/auth.service'

export const register_denuncia =
  (
    denuncia_user_id,
    denuncia_tipo,
    dataDevice,
    denuncia_Descripcion,
    denuncia_foto,
    denuncia_accuracy,
    denuncia_currentLatitude,
    denuncia_currentLongitude,
    denuncia_pais,
    denuncia_Nombres,
    denuncia_Apellidos,
    denuncia_Fono,
    denuncia_Email,
    denuncia_Usuario,
    denuncia_Role,
    denuncia_Prioridad,
    denuncia_Entidad,
    denuncia_dias,
    calle1,
    nuncalle,
    calle2,
    accessToken,
  ) =>
    (dispatch) => {
      return AuthService.register_denuncia(
        denuncia_user_id,
        denuncia_tipo,
        dataDevice,
        denuncia_Descripcion,
        denuncia_foto,
        denuncia_accuracy,
        denuncia_currentLatitude,
        denuncia_currentLongitude,
        denuncia_pais,
        denuncia_Nombres,
        denuncia_Apellidos,
        denuncia_Fono,
        denuncia_Email,
        denuncia_Usuario,
        denuncia_Role,
        denuncia_Prioridad,
        denuncia_Entidad,
        denuncia_dias,
        calle1,
        nuncalle,
        calle2,
        accessToken,
      ).then(
        (data) => {
          dispatch({
            type: REGISTER_SUCCESS,
            payload: { denuncia: data },
          })
          return Promise.resolve(data)
        },
        (error) => {
          const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
          dispatch({
            type: REGISTER_FAIL,
          })
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          })
          return Promise.reject()
        },
      )
    }

export const register_denuncia_olds =
  (
    denunciaOld_id,
    denunciaOld_fechahora,
    denunciaOld_ProcedimientoSGS,
    denunciaOld_Nombreyapellidos,
    denunciaOld_Telefono,
    denunciaOld_Direccion,
    denunciaOld_latlng,
    denunciaOld_Descripcion,
    accessToken,
  ) =>
    (dispatch) => {
      return AuthService.register_denuncia_olds(
        denunciaOld_id,
        denunciaOld_fechahora,
        denunciaOld_ProcedimientoSGS,
        denunciaOld_Nombreyapellidos,
        denunciaOld_Telefono,
        denunciaOld_Direccion,
        denunciaOld_latlng,
        denunciaOld_Descripcion,
        accessToken,
      ).then(
        (data) => {
          dispatch({
            type: REGISTER_SUCCESS,
            payload: { denuncia: data },
          })
          return Promise.resolve(data)
        },
        (error) => {
          const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
          dispatch({
            type: REGISTER_FAIL,
          })
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          })
          return Promise.reject()
        },
      )
    }

export const register_user =
  (
    user_nombre,
    user_apellido,
    user_fono,
    user_email,
    user_avatar,
    user_usuario,
    user_password,
    user_terminos,
    user_entidad,
    user_respuesta_sms,
    user_currentLatitude,
    user_currentLongitude,
    user_accuracy,
    user_pais,
  ) =>
    (dispatch) => {
      return AuthService.register_user(
        user_nombre,
        user_apellido,
        user_fono,
        user_email,
        user_avatar,
        user_usuario,
        user_password,
        user_terminos,
        user_entidad,
        user_respuesta_sms,
        user_currentLatitude,
        user_currentLongitude,
        user_accuracy,
        user_pais,
      ).then(
        (response) => {
          dispatch({
            type: REGISTER_SUCCESS,
          })

          dispatch({
            type: SET_MESSAGE,
            payload: response.data,
          })

          return Promise.resolve()
        },
        (error) => {
          const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()

          dispatch({
            type: REGISTER_FAIL,
          })

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          })

          return Promise.reject()
        },
      )
    }

export const login = (username, password, dataDevice) => (dispatch) => {
  return AuthService.login(username, password, dataDevice).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      })

      return Promise.resolve()
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()

      dispatch({
        type: LOGIN_FAIL,
      })

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      })

      return Promise.reject()
    },
  )
}

export const Url_caso = (numcaso, entidad, accessToken) => (dispatch) => {
  return AuthService.Url_caso(numcaso, entidad, accessToken).then(
    (data) => {
      return Promise.resolve(data)
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()

      dispatch({
        type: LOGIN_FAIL,
      })

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      })

      return Promise.reject()
    },
  )
}

export const ingresoantecedente =
  (
    antecedente_antecedentes,
    antecedente_denuncia_id,
    antecedente_user_id,
    antecedente_username,
    antecedente_avatar,
    antecedente_foto,
    antecedente_pais,
    antecedente_accuracy,
    antecedente_currentLatitude,
    antecedente_currentLongitude,
    denuncia_dias,
    accessToken,
  ) =>
    (dispatch) => {
      return AuthService.ingresoantecedente(
        antecedente_antecedentes,
        antecedente_denuncia_id,
        antecedente_user_id,
        antecedente_username,
        antecedente_avatar,
        antecedente_foto,
        antecedente_pais,
        antecedente_accuracy,
        antecedente_currentLatitude,
        antecedente_currentLongitude,
        denuncia_dias,
        accessToken,
      ).then(
        (data) => {
          return Promise.resolve(data)
        },
        (error) => {
          const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()

          dispatch({
            type: LOGIN_FAIL,
          })

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          })

          return Promise.reject()
        },
      )
    }

export const ingresoantecedenteCierre =
  (
    antecedente_antecedentes,
    antecedente_denuncia_id,
    antecedente_user_id,
    antecedente_username,
    antecedente_avatar,
    antecedente_foto,
    antecedente_pais,
    antecedente_accuracy,
    antecedente_currentLatitude,
    antecedente_currentLongitude,
    antecedente_medidas,
    antecedente_participantes,
    antecedente_detenidos,
    antecedente_cantidadDetenidos,
    denuncia_dias,
    accessToken,
  ) =>
    (dispatch) => {
      return AuthService.ingresoantecedenteCierre(
        antecedente_antecedentes,
        antecedente_denuncia_id,
        antecedente_user_id,
        antecedente_username,
        antecedente_avatar,
        antecedente_foto,
        antecedente_pais,
        antecedente_accuracy,
        antecedente_currentLatitude,
        antecedente_currentLongitude,
        antecedente_medidas,
        antecedente_participantes,
        antecedente_detenidos,
        antecedente_cantidadDetenidos,
        denuncia_dias,
        accessToken).then(
          (data) => {

            return Promise.resolve(data);
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            dispatch({
              type: LOGIN_FAIL,
            });

            dispatch({
              type: SET_MESSAGE,
              payload: message,
            });

            return Promise.reject();
          }
        );
    };

export const listAntecedentes = (
  denuncia_id,
  accessToken) => (dispatch) => {
    return AuthService.listAntecedentes(
      denuncia_id,
      accessToken).then(
        (data) => {

          return Promise.resolve(data);
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch({
            type: LOGIN_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });

          return Promise.reject();
        }
      );
  };

export const ingresoentidad = (entidad_name, entidad_direccion, entidad_accuracy, entidad_currentLatitude, entidad_currentLongitude, entidad_email, entidad_telefono, entidad_nomcontacto, entidad_logo, entidad_pais, entidad_TipoEntidad) => (dispatch) => {
  return AuthService.ingresoentidad(entidad_name, entidad_direccion, entidad_accuracy, entidad_currentLatitude, entidad_currentLongitude, entidad_email, entidad_telefono, entidad_nomcontacto, entidad_logo, entidad_pais, entidad_TipoEntidad).then(
    (data) => {

      return Promise.resolve(data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};



export const logout = () => (dispatch) => {

  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });



};

export const usertel = (email, username) => (dispatch) => {
  return AuthService.usertel(email, username).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};




export const cambiopass = (
  user_id,
  user_nombre,
  user_fono,
  user_email,
  user_usuario,
  user_password,
  user_respuesta_sms,
  user_pais,
  user_currentLatitude,
  user_currentLongitude,
  user_accuracy
) => (dispatch) => {
  return AuthService.cambiopass(
    user_id,
    user_nombre,
    user_fono,
    user_email,
    user_usuario,
    user_password,
    user_respuesta_sms,
    user_pais,
    user_currentLatitude,
    user_currentLongitude,
    user_accuracy
  ).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const cambioRol = (
  user_id,
  user_usuario,
  user_email,
  user_nombre,
  user_rolNew,
  unidad_id,
  unidMunicipalNom,
  accessToken
) => (dispatch) => {
  return AuthService.cambioRol(
    user_id,
    user_usuario,
    user_email,
    user_nombre,
    user_rolNew,
    unidad_id,
    unidMunicipalNom,
    accessToken
  ).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const ingresoPlan = (
  tipo_planes,
  user_id,
  entidades,
  currentLatitude,
  currentLongitude,
  currentaccuracy,
  ubica,
  descripcion,
  accessToken
) => (dispatch) => {
  return AuthService.ingresoPlan(
    tipo_planes,
    user_id,
    entidades,
    currentLatitude,
    currentLongitude,
    currentaccuracy,
    ubica,
    descripcion,
    accessToken
  ).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const cambioEstadoPlan = (
  plan_id,
  plan_estadoNew,
  accessToken
) => (dispatch) => {
  return AuthService.cambioEstadoPlan(
    plan_id,
    plan_estadoNew,
    accessToken
  ).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const cambioEstado = (
  user_id,
  user_usuario,
  user_email,
  user_nombre,
  user_estadoNew,
  accessToken
) => (dispatch) => {
  return AuthService.cambioEstado(
    user_id,
    user_usuario,
    user_email,
    user_nombre,
    user_estadoNew,
    accessToken
  ).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const contactoemergencia = (
  user_id,
  user_nombre,
  user_fono,
  user_contacto1,
  user_contacto2,
  user_email,
  user_usuario,
  user_pais,
  user_currentLatitude,
  user_currentLongitude,
  user_accuracy
) => (dispatch) => {
  return AuthService.contactoemergencia(
    user_id,
    user_nombre,
    user_fono,
    user_contacto1,
    user_contacto2,
    user_email,
    user_usuario,
    user_pais,
    user_currentLatitude,
    user_currentLongitude,
    user_accuracy
  ).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const cambioavatar = (
  user_id,
  user_nombre,
  user_fono,
  user_email,
  user_usuario,
  user_avatar,
  user_pais,
  user_currentLatitude,
  user_currentLongitude,
  user_accuracy
) => (dispatch) => {
  return AuthService.cambioavatar(
    user_id,
    user_nombre,
    user_fono,
    user_email,
    user_usuario,
    user_avatar,
    user_pais,
    user_currentLatitude,
    user_currentLongitude,
    user_accuracy
  ).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const insertafotoAntecedente = (
  antecedente_id,
  denuncia_id,
  user_id,
  antecedente_foto,
  accessToken
) => (dispatch) => {
  return AuthService.insertafotoAntecedente(
    antecedente_id,
    denuncia_id,
    user_id,
    antecedente_foto,
    accessToken
  ).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const insertafotoDenuncia = (
  denuncia_id,
  username,
  email,
  nombres,
  denuncia_foto,
  accessToken
) => (dispatch) => {
  return AuthService.insertafotoDenuncia(
    denuncia_id,
    username,
    email,
    nombres,
    denuncia_foto,
    accessToken
  ).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const lisDen = (
  estado,
  entidad,
  denuncia_tipo,
  varDay,
  derivacion,
  accessToken
) => (dispatch) => {
  return AuthService.lisDen(
    estado,
    entidad,
    denuncia_tipo,
    varDay,
    derivacion,
    accessToken
  ).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error.response);
      const message =
        error.response ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const ingresoturno = (user_id,
  tipo_user,
  lat_ini,
  lng_ini,
  currentaccuracy_ini,
  tipo_movil,
  codigo_movil,
  kmIni_movil,
  codigo_radio,
  asignacion_user,
  dataDevice_Ini,
  accessToken) => (dispatch) => {
    return AuthService.ingresoturno(user_id,
      tipo_user,
      lat_ini,
      lng_ini,
      currentaccuracy_ini,
      tipo_movil,
      codigo_movil,
      kmIni_movil,
      codigo_radio,
      asignacion_user,
      dataDevice_Ini,
      accessToken).then(
        (data) => {
          return Promise.resolve(data)
        },
        (error) => {
          const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          })

          return Promise.reject()
        },
      )
  }

export const salidaturno = (user_id,
  tipo_user,
  lat_ter,
  lng_ter,
  currentaccuracy_ter,
  kmTer_movil,
  novedadesturno,
  dataDevice_Ter,
  accessToken) => (dispatch) => {
    return AuthService.salidaturno(user_id,
      tipo_user,
      lat_ter,
      lng_ter,
      currentaccuracy_ter,
      kmTer_movil,
      novedadesturno,
      dataDevice_Ter,
      accessToken).then(
        (data) => {
          return Promise.resolve(data)
        },
        (error) => {
          const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          })

          return Promise.reject()
        },
      )
  }

export const enturno = (user_id, tipo_user, accessToken) => (dispatch) => {
  return AuthService.enturno(user_id, tipo_user, accessToken).then(
    (data) => {
      return Promise.resolve(data)
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      })

      return Promise.reject()
    },
  )
}

export const listTurnos = (estado_turno, tipo_user, accessToken) => (dispatch) => {
  return AuthService.enturno(estado_turno, tipo_user, accessToken).then(
    (data) => {
      return Promise.resolve(data)
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      })

      return Promise.reject()
    },
  )
}

export const findUserGeoUltima = (userid, username, accessToken) => (dispatch) => {
  return AuthService.findUserGeoUltima(userid, username, accessToken).then(
    (data) => {
      return Promise.resolve(data)
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      })

      return Promise.reject()
    },
  )
}

export const listTurnosDisponible = (estado_turno, tipo_user, disponible, accessToken) => (dispatch) => {
  return AuthService.listTurnosDisponible(estado_turno, tipo_user, disponible, accessToken).then(
    (data) => {
      return Promise.resolve(data)
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      })

      return Promise.reject()
    },
  )
}

export const findUserGeoUltimaGeo = (lat, lng, accessToken) => (dispatch) => {
  return AuthService.findUserGeoUltimaGeo(lat, lng, accessToken).then(
    (data) => {
      return Promise.resolve(data)
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      })

      return Promise.reject()
    },
  )
}

export const insertadespacho = (
  denuncia_id,
  denunciaid,
  user_id,
  estado_asignacion,
  Codigo_Operador,
  Username_Operador,
  UserOperador_id,
  Codigo_Seguridad,
  Username_Seguridad,
  UserSeguridad_id,
  Seguridad_Avatar,
  Tipo_movil,
  Codigo_movil,
  Asignacion_user,
  Distancia_Asignacion,
  Fechahora_Ult_Pos_Asignacion,
  Location_Asignacion,
  accuracy,
  denuncia_dias,
  LatProc,
  LngProc,
  TelefenoSeguridad,
  nomprioridad,
  nameDescripcion,
  Turno_user_id,
  accessToken) => (dispatch) => {

    return AuthService.insertadespacho(
      denuncia_id,
      denunciaid,
      user_id,
      estado_asignacion,
      Codigo_Operador,
      Username_Operador,
      UserOperador_id,
      Codigo_Seguridad,
      Username_Seguridad,
      UserSeguridad_id,
      Seguridad_Avatar,
      Tipo_movil,
      Codigo_movil,
      Asignacion_user,
      Distancia_Asignacion,
      Fechahora_Ult_Pos_Asignacion,
      Location_Asignacion,
      accuracy,
      denuncia_dias,
      LatProc,
      LngProc,
      TelefenoSeguridad,
      nomprioridad,
      nameDescripcion,
      Turno_user_id,
      accessToken
    ).then(
      (data) => {
        return Promise.resolve(data)
      },
      (error) => {
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString()

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        })

        return Promise.reject()
      },
    )
  }

export const insertaInicioProc = (
  denuncia_id,
  denunciaid,
  user_id,
  estado_asignacion,
  Codigo_Seguridad,
  Username_Seguridad,
  Seguridad_Avatar,
  Fechahora_Ult_Pos_Inicio,
  Location_Inicio,
  accuracy_Inicio,
  denuncia_dias,
  accessToken) => (dispatch) => {
    return AuthService.insertaInicioProc(
      denuncia_id,
      denunciaid,
      user_id,
      estado_asignacion,
      Codigo_Seguridad,
      Username_Seguridad,
      Seguridad_Avatar,
      Fechahora_Ult_Pos_Inicio,
      Location_Inicio,
      accuracy_Inicio,
      denuncia_dias,
      accessToken
    ).then(
      (data) => {
        return Promise.resolve(data)
      },
      (error) => {
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString()

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        })

        return Promise.reject()
      },
    )
  }


export const insertaTerminoProc = (
  denuncia_id,
  denunciaid,
  user_id,
  Fechahora_Ult_Pos_Termino,
  Location_Termino,
  accuracy_Termino,
  Id_Seguridad_turno,
  accessToken) => (dispatch) => {
    return AuthService.insertaTerminoProc(
      denuncia_id,
      denunciaid,
      user_id,
      Fechahora_Ult_Pos_Termino,
      Location_Termino,
      accuracy_Termino,
      Id_Seguridad_turno,
      accessToken
    ).then(
      (data) => {
        return Promise.resolve(data)
      },
      (error) => {
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString()

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        })

        return Promise.reject()
      },
    )
  }


export const lisDenTipo = (
  entidad,
  id_prioridad,
  fecha_ini,
  fecha_fin,
  derivacion,
  accessToken
) => (dispatch) => {
  return AuthService.lisDenTipo(
    entidad,
    id_prioridad,
    fecha_ini,
    fecha_fin,
    derivacion,
    accessToken
  ).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const register_parte =
  (
    parte_user_id,
    parte_nombres,
    parte_apellidos,
    parte_email,
    parte_ubica,
    parte_username,
    parte_entidad,
    parte_role,
    parte_dataDevice,
    parte_Infraccion_tipo,
    parte_Tipo_Infraccion,
    parte_Nom_Infraccion,
    parte_foto,
    parte_currentLatitude,
    parte_currentLongitude,
    parte_currentaccuracy,
    parte_Fechahora_Infraccion,
    parte_Num_Boleta,
    parte_Patente_PPU,
    parte_Fechahora_CitacionJPL,
    parte_Tipo_Vehiculo,
    parte_Marca,
    parte_Color,
    parte_Forma_Entrega,
    parte_Calle1,
    parte_Nuncalle,
    parte_Calle2,
    parte_Observacion,
    parte_dias,
    parte_LaborEducativa,
    accessToken,
    TextRUT,
    TextNOMBRE,
    TextDOMICILIO,
  ) =>
    (dispatch) => {
      return AuthService.register_parte(
        parte_user_id,
        parte_nombres,
        parte_apellidos,
        parte_email,
        parte_ubica,
        parte_username,
        parte_entidad,
        parte_role,
        parte_dataDevice,
        parte_Infraccion_tipo,
        parte_Tipo_Infraccion,
        parte_Nom_Infraccion,
        parte_foto,
        parte_currentLatitude,
        parte_currentLongitude,
        parte_currentaccuracy,
        parte_Fechahora_Infraccion,
        parte_Num_Boleta,
        parte_Patente_PPU,
        parte_Fechahora_CitacionJPL,
        parte_Tipo_Vehiculo,
        parte_Marca,
        parte_Color,
        parte_Forma_Entrega,
        parte_Calle1,
        parte_Nuncalle,
        parte_Calle2,
        parte_Observacion,
        parte_dias,
        parte_LaborEducativa,
        accessToken,
        TextRUT,
        TextNOMBRE,
        TextDOMICILIO,
      ).then(
        (data) => {
          dispatch({
            type: REGISTER_SUCCESS,
            payload: { denuncia: data },
          })
          return Promise.resolve(data)
        },
        (error) => {
          const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
          dispatch({
            type: REGISTER_FAIL,
          })
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          })
          return Promise.reject()
        },
      )
    }

export const insertafotoParte = (
  opcion,
  denuncia_id,
  username,
  email,
  nombres,
  denuncia_foto,
  accessToken
) => (dispatch) => {
  return AuthService.insertafotoParte(
    opcion,
    denuncia_id,
    username,
    email,
    nombres,
    denuncia_foto,
    accessToken
  ).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const lisMisPartesTipo = (
  entidad,
  id_prioridad,
  fecha_ini,
  fecha_fin,
  accessToken
) => (dispatch) => {
  return AuthService.lisMisPartesTipo(
    entidad,
    id_prioridad,
    fecha_ini,
    fecha_fin,
    accessToken
  ).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const insertaNumBoleta = (
  _id,
  infraccion_id,
  Num_Boleta,
  accessToken
) => (dispatch) => {
  return AuthService.insertaNumBoleta(
    _id,
    infraccion_id,
    Num_Boleta,
    accessToken
  ).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const ingresoantecedenteCierreDeriva =
  (
    antecedente_antecedentes,
    antecedente_denuncia_id,
    antecedente_user_id,
    antecedente_username,
    antecedente_avatar,
    antecedente_foto,
    antecedente_pais,
    antecedente_accuracy,
    antecedente_currentLatitude,
    antecedente_currentLongitude,
    antecedente_medidas,
    antecedente_participantes,
    antecedente_detenidos,
    antecedente_cantidadDetenidos,
    denuncia_dias,
    accessToken,
  ) =>
    (dispatch) => {
      return AuthService.ingresoantecedenteCierreDeriva(
        antecedente_antecedentes,
        antecedente_denuncia_id,
        antecedente_user_id,
        antecedente_username,
        antecedente_avatar,
        antecedente_foto,
        antecedente_pais,
        antecedente_accuracy,
        antecedente_currentLatitude,
        antecedente_currentLongitude,
        antecedente_medidas,
        antecedente_participantes,
        antecedente_detenidos,
        antecedente_cantidadDetenidos,
        denuncia_dias,
        accessToken).then(
          (data) => {

            return Promise.resolve(data);
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            dispatch({
              type: LOGIN_FAIL,
            });

            dispatch({
              type: SET_MESSAGE,
              payload: message,
            });

            return Promise.reject();
          }
        );
    };

export const insertaplanintervencion =
  (
    plan_codigo,
    plan_nombre,
    plan_apellido,
    plan_telefono,
    plan_contacto1,
    plan_contacto2,
    plan_email,
    plan_entedad,
    plan_id,
    plan_rol,
    plan_dataVecino,
    plan_detalles,
    plan_latitud,
    plan_longitud,
    plan_direccion,
    plan_planNombre,
    accessToken
  ) =>
    (dispatch) => {
      return AuthService.insertaplanintervencion(
        plan_codigo,
        plan_nombre,
        plan_apellido,
        plan_telefono,
        plan_contacto1,
        plan_contacto2,
        plan_email,
        plan_entedad,
        plan_id,
        plan_rol,
        plan_dataVecino,
        plan_detalles,
        plan_latitud,
        plan_longitud,
        plan_direccion,
        plan_planNombre,
        accessToken).then(
          (data) => {

            return Promise.resolve(data);
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            dispatch({
              type: LOGIN_FAIL,
            });

            dispatch({
              type: SET_MESSAGE,
              payload: message,
            });

            return Promise.reject();
          }
        );
    };


export const insertaplanintervencion_censo =
  (
    plan_censo_username,
    plan_censo_nombre,
    plan_censo_apellido,
    plan_censo_telefono,
    accessToken
  ) =>
    (dispatch) => {
      return AuthService.insertaplanintervencion_censo(
        plan_censo_username,
        plan_censo_nombre,
        plan_censo_apellido,
        plan_censo_telefono,
        accessToken).then(
          (data) => {

            return Promise.resolve(data);
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            dispatch({
              type: LOGIN_FAIL,
            });

            dispatch({
              type: SET_MESSAGE,
              payload: message,
            });

            return Promise.reject();
          }
        );
    };

export const insertaplanintervencion_mas_seguridad =
  (
    plan_mas_seguridad_username,
    plan_mas_seguridad_nombre,
    plan_mas_seguridad_apellido,
    plan_mas_seguridad_telefono,
    accessToken
  ) =>
    (dispatch) => {
      return AuthService.insertaplanintervencion_mas_seguridad(
        plan_mas_seguridad_username,
        plan_mas_seguridad_nombre,
        plan_mas_seguridad_apellido,
        plan_mas_seguridad_telefono,
        accessToken).then(
          (data) => {

            return Promise.resolve(data);
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            dispatch({
              type: LOGIN_FAIL,
            });

            dispatch({
              type: SET_MESSAGE,
              payload: message,
            });

            return Promise.reject();
          }
        );
    };

export const prom_dias_derivaciones =
  (
    id_unidad,
    accessToken
  ) =>
    (dispatch) => {
      return AuthService.prom_dias_derivaciones(
        id_unidad,
        accessToken).then(
          (data) => {

            return Promise.resolve(data);
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            dispatch({
              type: LOGIN_FAIL,
            });

            dispatch({
              type: SET_MESSAGE,
              payload: message,
            });

            return Promise.reject();
          }
        );
    };

export const findDenunciaDistancia = (lat, lng, distancia, derivada, estado, accessToken) => (dispatch) => {
  return AuthService.findDenunciaDistancia(lat, lng, distancia, derivada, estado, accessToken).then(
    (data) => {
      return Promise.resolve(data)
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      })

      return Promise.reject()
    },
  )
}


export const findInfraccionesDistancia = (lat, lng, distancia, dias, accessToken) => (dispatch) => {
  return AuthService.findInfraccionesDistancia(lat, lng, distancia, dias, accessToken).then(
    (data) => {
      return Promise.resolve(data)
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      })

      return Promise.reject()
    },
  )
}

export const origen_Denuncias = (
  entidad,
  fecha_ini,
  fecha_fin,
  accessToken
) => (dispatch) => {
  return AuthService.origen_Denuncias(
    entidad,
    fecha_ini,
    fecha_fin,
    accessToken
  ).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const usuarios_Denuncias = (
  entidad,
  fecha_ini,
  fecha_fin,
  accessToken
) => (dispatch) => {
  return AuthService.usuarios_Denuncias(
    entidad,
    fecha_ini,
    fecha_fin,
    accessToken
  ).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const participantes_Denuncias = (
  entidad,
  fecha_ini,
  fecha_fin,
  accessToken
) => (dispatch) => {
  return AuthService.participantes_Denuncias(
    entidad,
    fecha_ini,
    fecha_fin,
    accessToken
  ).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const detenidos_Denuncias = (
  entidad,
  fecha_ini,
  fecha_fin,
  accessToken
) => (dispatch) => {
  return AuthService.detenidos_Denuncias(
    entidad,
    fecha_ini,
    fecha_fin,
    accessToken
  ).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const operadores_DenunciasDespachadas = (
  entidad,
  fecha_ini,
  fecha_fin,
  accessToken
) => (dispatch) => {
  return AuthService.operadores_DenunciasDespachadas(
    entidad,
    fecha_ini,
    fecha_fin,
    accessToken
  ).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const seguridad_DenunciasDespachadas = (
  entidad,
  fecha_ini,
  fecha_fin,
  accessToken
) => (dispatch) => {
  return AuthService.seguridad_DenunciasDespachadas(
    entidad,
    fecha_ini,
    fecha_fin,
    accessToken
  ).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};