import axios from "axios";

const API_URL = process.env.REACT_APP_API_API_URL


class ElementosService {

  getEntidades() {    
    return axios
      .get(API_URL + "entidades", { })
      .then((response) => {
        //console.log(JSON.stringify(response.data.entidadList));
        //return JSON.stringify(response.data);
        
        //console.log(response.data);
        return response.data.entidadList;
        
      });
  }
  

  getTipoEntidades() {    
    return axios
      .get(API_URL + "tipoentidades", { })
      .then((response) => {
        //console.log(JSON.stringify(response.data.entidadList));
        //return JSON.stringify(response.data);
        
        //console.log(response.data.entidadList);
        return response.data.tipoentidadList;
        
      });
  }

  getRoles() {    
    return axios
      .get(API_URL + "roles", { })
      .then((response) => {
        return response.data;
      });
  }

  getTipos() {    
    return axios
      .get(API_URL + "tipos", { })
      .then((response) => {
        return response.data;
      }); 
  }

  getPrioridades(TipoPrioridad) {   

    return axios
      .post(API_URL + "prioridades", { TipoPrioridad: TipoPrioridad })
      .then((response) => {

        return response.data.prioridadList;
      }).catch(error => {
        console.log(error);
      });
  }

  getPrioridades(TipoPrioridad) {   

    return axios
      .post(API_URL + "prioridades", { TipoPrioridad: TipoPrioridad })
      .then((response) => {

        return response.data.prioridadList;
      }).catch(error => {
        console.log(error);
      });
  }

  getUnidades(unidad_id) {   
    
    return axios
      .post(API_URL + "unidades", { unidad_id: unidad_id })
      .then((response) => {
        return response.data.unidadesList;
      }).catch(error => {
        console.log(error);
      });
  }

  getTipoInfracciones(TipoInfraccion) {   
    return axios
      .post(API_URL + "tipoinfracciones", { TipoInfraccion: TipoInfraccion })
      .then((response) => {
        return response.data.tipoinfraccionList;
      }).catch(error => {
        console.log(error);
      });
  }

  getPrioridadesTipo() {   

    return axios
      .post(API_URL + "prioridadestipo", {})
      .then((response) => {

        return response.data.prioridadList;
      }).catch(error => {
        console.log(error);
      });
  }

  getPrioridadesTipoUM() {   

    return axios
      .post(API_URL + "prioridadestipoum", {})
      .then((response) => {

        return response.data.prioridadList;
      }).catch(error => {
        console.log(error);
      });
  }
  
  getInfraccionesTipo() {   

    return axios
      .post(API_URL + "infraccionestipo", {})
      .then((response) => {

        return response.data.infracionesList;
      }).catch(error => {
        console.log(error);
      });
  }

  getCalles() {   
    
    return axios
      .post(API_URL + "allCalles")
      .then((response) => {

        return response.data.calles;
      }).catch(error => {

        console.log(error);
      });
  }

  getInfractor(RutInfractor, entidad) {   

    console.log('RutInfractor: ', RutInfractor)

    return axios
      .post(API_URL + "infractor", { RutInfractor: RutInfractor, entidad: entidad })
      .then((response) => {
        return response.data.datosInfractor;
      }).catch(error => {
        console.log('getInfractor error: ', error);
      });
  }

}

export default new ElementosService();